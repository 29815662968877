import React from 'react';
import '../styles/Home.css'; // Assuming you want to keep using the same styles
import CubeBackground3D from './CubeBackground3D';
import MainHeader from "./MainHeader";
import ArtistList from "./ArtistList";

const Home = () => {
    return (
        <div className="Home">
            <CubeBackground3D/>
            <section id="home">
                <MainHeader/>
            </section>
            <section id="artists">
                <ArtistList/>
            </section>
            <footer>
                <p>Empty Orchestra 2022</p>
            </footer>
        </div>
    );
};

export default Home;
