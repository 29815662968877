import React from 'react';
import ArtistCard from './ArtistCard';
import artists from '../data/artists';  // Adjust the import path as necessary
import '../styles/ArtistList.css';

const ArtistList = () => {
    return (
        <div className="artist-list">
            {artists.map((artist) => (
                <ArtistCard key={artist.id} artist={artist}/>
            ))}
        </div>
    );
};

export default ArtistList;
