import React, { useState } from 'react';
import '../styles/ArtistCard.css';

const ArtistCard = ({ artist }) => {
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);

    const nextMedia = () => {
        setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % artist.media.length);
    };

    const prevMedia = () => {
        setCurrentMediaIndex((prevIndex) => (prevIndex - 1 + artist.media.length) % artist.media.length);
    };

    const renderMedia = (media) => {
        if (media.type === "video") {
            return (
                <video controls autoPlay loop muted>
                    <source src={media.url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            );
        } else {
            return <img src={media.url} alt={artist.name} />;
        }
    };

    return (
        <div className="artist-card">
            <div className="media-gallery">
                <button onClick={prevMedia}>&lt;</button>
                {renderMedia(artist.media[currentMediaIndex])}
                <button onClick={nextMedia}>&gt;</button>
            </div>
            <div className="artist-info">
                {artist.link ? (
                    <a href={artist.link} target="_blank" rel="noopener noreferrer" className="artist-link">
                        <h2>{artist.name}</h2>
                    </a>
                ) : (
                    <h2>{artist.name}</h2>
                )}
                <p>{artist.description}</p>
            </div>
        </div>
    );
};

export default ArtistCard;
