
import React from 'react';
import '../styles/ClothingSoon.css';
const ClothingSoon = () => {
    return (
        <div className="Clothing-soon">
            <h1>Soon.</h1>
            <p>Check back later for more clothing items!</p>
        </div>
    );
};
export default ClothingSoon;