import React from 'react';
import '../styles/Contact.css'; // Ensure this path matches your project structure

const Contact = () => {
    return (
        <div className="contact-container">
            <a href="mailto:emptyorchestra@gmail.com" className="email-link">
                emptyorchestralabel@gmail.com
            </a>
        </div>
    );
};

export default Contact;
