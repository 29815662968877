import React from 'react';
import '../styles/Social.css'; // Make sure the CSS file is properly linked

const Social = () => {
    return (
        <div className="social-container">
            <a href="https://www.instagram.com/emptyorchestralabel/" target="_blank" rel="noopener noreferrer" className="social-link">
                (instagram)
            </a>
            <a href="https://www.tiktok.com/@emptyorchestralabel" target="_blank" rel="noopener noreferrer" className="social-link">
                (tiktok)
            </a>
            <a href="https://www.youtube.com/@emptyorchestralabel" target="_blank" rel="noopener noreferrer" className="social-link">
                (youtube)
            </a>
            <a href="https://x.com/eothelabel" target="_blank" rel="noopener noreferrer" className="social-link">
                (x)
            </a>
        </div>
    );
};

export default Social;
