import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleArtistClick = () => {
        if (location.pathname === '/') {
            // Already on the homepage, scroll to the artists section
            const artistSection = document.getElementById('artists');
            if (artistSection) {
                artistSection.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // Navigate to the homepage and scroll to the artists section
            navigate('/');
            setTimeout(() => {
                const artistSection = document.getElementById('artists');
                if (artistSection) {
                    artistSection.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100); // Delay to allow the homepage to load before scrolling
        }
    };

    return (
        <nav className="navbar">
            <ul className="nav-links">
                <li><button className="link-button" onClick={() => navigate('/')}>Höme</button></li>
                <li><button className="link-button" onClick={handleArtistClick}>Ärtists</button></li>
                <li><button className="link-button" onClick={() => navigate('/about')}>Äböut</button></li>
                {/*<li><a href="#portfolio">Pörtföliö</a></li>*/}

                {/*<li className="dropdown">
                    <a href="#clothing" onClick={() => navigate('/clothing')}>Clöthing</a>
                    <ul className="dropdown-content">
                        <li><a href="#summer">Summer Collection</a></li>
                        <li><a href="#album">Album Collection</a></li>
                    </ul>
                </li>
                */}
                {/*<li><a href="#clothingsoon" onClick={() => navigate('/clothingsoon')}>Clöthing</a></li>*/}
                <li><button className="link-button" onClick={() => navigate('/radio')}>Rädiö</button></li>
                <li><button className="link-button" onClick={() => navigate('/social')}>Söcial</button></li>
                <li><button className="link-button" onClick={() => navigate('/contact')}>Cöntåct</button></li>

            </ul>
        </nav>
    );
};

export default Navbar;