import React from 'react';
import '../styles/Radio.css'; // Make sure the CSS file is properly linked

const Radio = () => {
    return (
        <div className="radio-container">
            <h1>(empty) orchestra radio</h1>
            <p>
                This playlist is a carefully curated selection of tracks that are of the collective's liking. Feel free
                to contact us with suggestions.
            </p>
            <iframe
                style={{ borderRadius: '12px' }}
                src="https://open.spotify.com/embed/playlist/7vBlJkyL1uoO0SCV1KSTgk?utm_source=generator&theme=0"
                width="100%"
                height="352"
                frameBorder="0"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                title="Spotify Playlist"
            ></iframe>
            <p>
                Updated every monday.
            </p>
        </div>
    );
};

export default Radio;
