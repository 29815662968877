import '../styles/MainHeader.css';

import React, {useState, useEffect} from 'react';

const MainHeader = () => {
    const [opacity, setOpacity] = useState(1); // MainHeader is fully visible initially

    useEffect(() => {
        const handleScroll = () => {
            const maxScroll = window.innerHeight; // Maximum scroll value for full fade
            const currentScroll = window.scrollY;
            const newOpacity = Math.max(0, 1 - (currentScroll / maxScroll) * 2);
            setOpacity(newOpacity);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header style={{opacity}} className="MainHeader-header">
            <h1>(empty) orchestra</h1>
        </header>
    );
};

export default MainHeader;
