import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from "./components/Navbar";
import Home from './components/Home';
import Clothing from './components/Clothing';
import About from './components/About';
import Contact from './components/Contact'; // Import the new Contact component
import Radio from './components/Radio';  // Import the new Radio component
import Social from './components/Social'; // Import the new Social component

import ClothingSoon from "./components/ClothingSoon";
import './App.css';


function App() {
    return (
        <Router>
            <div className="App">
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/clothing" element={<Clothing />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/clothingsoon" element={<ClothingSoon />} />
                    <Route path="/contact" element={<Contact />} /> {/* New contact route */}
                    <Route path="/radio" element={<Radio />} />
                    <Route path="/social" element={<Social />} /> {/* New social route */}
                    {/* Add other routes as needed */}
                </Routes>
            </div>
        </Router>
    );
}

export default App;