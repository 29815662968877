const clothingData = [
    {
        id: 1,
        name: "T-Shirt",
        description: "A comfortable cotton t-shirt",
        price: 19.99,
        image: "assets/images/clothing/1.webp"
    },
    {
        id: 2,
        name: "Jeans",
        description: "Stylish blue jeans",
        price: 49.99,
        image: "assets/images/clothing/2.webp"
    },
    {
        id: 3,
        name: "Sweater",
        description: "Warm wool sweater",
        price: 59.99,
        image: "assets/images/clothing/3.webp"
    },
        {
        id: 4,
        name: "Sweater",
        description: "Warm wool sweater",
        price: 59.99,
        image: "assets/images/clothing/4.webp"
    },
        {
        id: 5,
        name: "Sweater",
        description: "Warm wool sweater",
        price: 59.99,
        image: "assets/images/clothing/5.webp"
    },
        {
        id: 6,
        name: "Sweater",
        description: "Warm wool sweater",
        price: 59.99,
        image: "assets/images/clothing/6.webp"
    },
        {
        id: 7,
        name: "Sweater",
        description: "Warm wool sweater",
        price: 59.99,
        image: "assets/images/clothing/7.webp"
    },
    // Add more clothing items as needed
];

export default clothingData;