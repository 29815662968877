import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const BallBackground3D = () => {
    const mountRef = useRef(null);
    const ballRef = useRef(null);

    useEffect(() => {
        const mount = mountRef.current;
        const width = mount.clientWidth;
        const height = mount.clientHeight;
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(width, height);
        mount.appendChild(renderer.domElement);

        // Video texture setup
        const video = document.createElement('video');
        video.src = 'assets/videos/eo_mix_video.mp4'; // Update the path to your video file
        video.loop = true;
        video.muted = true;
        video.play(); // Ensure the video plays on load
        const texture = new THREE.VideoTexture(video);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.format = THREE.RGBFormat;

        // Ball setup
        const geometry = new THREE.SphereGeometry(2, 32, 32);
        const material = new THREE.MeshBasicMaterial({ map: texture });
        const ball = new THREE.Mesh(geometry, material);
        scene.add(ball);
        ballRef.current = ball;

        camera.position.z = 5;

        const animate = function () {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        };

        animate();

        // Function to update ball rotation
        const handleScroll = () => {
            const scrollY = window.scrollY;
            if (ballRef.current) {
                ballRef.current.rotation.y = scrollY * 0.01;
                ballRef.current.rotation.x = scrollY * 0.01;
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup function
        return () => {
            video.pause();
            video.src = ""; // Clear the video source to release memory
            window.removeEventListener('scroll', handleScroll);
            if (mount) {
                mount.removeChild(renderer.domElement);
            }
            renderer.dispose();
        };
    }, []);

    return <div ref={mountRef} style={{ width: '100%', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: -1 }} />;
};

export default BallBackground3D;
