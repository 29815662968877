// src/data/artists.js

const artists = [
    {
        id: 1,
        name: "Cosmo Swain",
        media: [
            {url: "assets/images/artists/cosmoswain/1.gif", type: "image"},
            {url: "assets/images/artists/cosmoswain/2.jpg", type: "image"},
            {url: "assets/images/artists/cosmoswain/3.jpg", type: "image"},
            {url: "assets/images/artists/cosmoswain/4.jpg", type: "image"},
            {url: "assets/images/artists/cosmoswain/5.jpg", type: "image"},
            {url: "assets/images/artists/cosmoswain/6.jpg", type: "image"},
            {url: "assets/images/artists/cosmoswain/7.jpg", type: "image"},
            {url: "assets/images/artists/cosmoswain/8.jpg", type: "image"},
            {url: "assets/images/artists/cosmoswain/9.jpg", type: "image"},
            {url: "assets/images/artists/cosmoswain/10.jpg", type: "image"},

            // Other media
        ],
        description: "(empty) orchestra",
        genre: "Orchestral Pop, Indie Pop",
        link: "https://open.spotify.com/artist/4kZLnDMjTKER3Kh7eRuo01?si=4ZDbrw9gQdOhfNjzXzxEwA",
    },
    {
        id: 2,
        name: "Wanhov",
        media: [
            {url: "assets/images/artists/wanhov/1.gif", type: "image"},
            {url: "assets/images/artists/wanhov/2.jpg", type: "image"},
            {url: "assets/images/artists/wanhov/3.gif", type: "image"},
            {url: "assets/images/artists/wanhov/4.png", type: "image"},
            {url: "assets/images/artists/wanhov/5.jpg", type: "image"},
            {url: "assets/images/artists/wanhov/6.jpg", type: "image"},
            {url: "assets/images/artists/wanhov/7.jpg", type: "image"},

            // Other media
        ],
        description: "(empty) orchestra",
        genre: "Retard Rock",
        link: "https://open.spotify.com/artist/0xflXgmwvaboEXWHNqfEfm?si=c2cwKHTQSmuKvFdPaojOpg"
    },
    {
        id: 3,
        name: "Greiz",
        media: [
            {url: "assets/images/artists/greiz/1.jpeg", type: "image"},
            {url: "assets/images/artists/greiz/2.PNG", type: "image"},
            {url: "assets/images/artists/greiz/3.PNG", type: "image"},
            {url: "assets/images/artists/greiz/4.PNG", type: "image"},
            {url: "assets/images/artists/greiz/5.JPG", type: "image"},
            {url: "assets/images/artists/greiz/6.JPG", type: "image"},


            // Other media
        ],
        description: "(empty) orchestra",
        genre: "Orchestral Pop, Indie Pop",
    },
    {
        id: 4,
        name: "Astrante",
        media: [
            {url: "assets/images/artists/astrante/1.JPG", type: "image"},
            {url: "assets/images/artists/astrante/2.JPG", type: "image"},
            {url: "assets/images/artists/astrante/3.JPG", type: "image"},
            {url: "assets/images/artists/astrante/4.JPG", type: "image"},
            {url: "assets/images/artists/astrante/5.JPG", type: "image"},
            {url: "assets/images/artists/astrante/6.JPG", type: "image"},
            {url: "assets/images/artists/astrante/7.PNG", type: "image"},
            {url: "assets/images/artists/astrante/8.PNG", type: "image"},



            // Other media
        ],
        description: "(empty) orchestra",
        genre: "Orchestral Pop, Indie Pop",
        link: "https://open.spotify.com/artist/6hBlHjTsTDXY5Sezm520eA?si=2451479191824a4a"
    },
    {
        id: 5,
        name: "Helios",
        media: [
            {url: "assets/images/artists/helios/1.JPG", type: "image"},
            {url: "assets/images/artists/helios/2.JPG", type: "image"},


            // Other media
        ],
        description: "(empty) orchestra",
        genre: "Orchestral Pop, Indie Pop",
        link: "https://open.spotify.com/artist/0ic1EcVPqxRprdU7XIpEbT?si=W9y9q4VyQ7CvqVFe5uJtMQ"
    }

    // More artists...
];

export default artists;
