import React from 'react';
import '../styles/Clothing.css';
import clothingData from '../data/clothingdata'; // Import clothingData here
import BallBackground3D from './BallBackground3D';

const ClothingItem = ({ item }) => {
    return (
        <div className="clothing-item">
            <img src={item.image} alt={item.name} />
            <h3>{item.name}</h3>
            <p>{item.description}</p>
            <p>${item.price}</p>
            <button onClick={() => alert('Added to cart')}>Buy Now</button>
        </div>
    );
};

const Clothing = () => { // No need for items prop anymore
    return (
        <div>
            <BallBackground3D />
            <div className="clothing-page">
                {clothingData.map(item => <ClothingItem key={item.id} item={item} />)}
            </div>
        </div>


    );
};

export default Clothing;