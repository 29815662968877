import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

const CubeBackground3D = () => {
    const mountRef = useRef(null);
    const cubeRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Mobile breakpoint
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isMobile || !mountRef.current) return;

        const mount = mountRef.current;
        const width = mount.clientWidth;
        const height = mount.clientHeight;
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(width, height);
        mount.appendChild(renderer.domElement);

        const video = document.createElement('video');
        video.src = 'assets/videos/eo_mix_video.mp4';
        video.loop = true;
        video.muted = true;
        video.play();
        const texture = new THREE.VideoTexture(video);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.format = THREE.RGBFormat;

        const geometry = new THREE.BoxGeometry(4.25, 2);
        const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true, opacity: 1 });
        const cube = new THREE.Mesh(geometry, material);
        scene.add(cube);
        cubeRef.current = cube;

        camera.position.z = 5;

        const animate = function () {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        };

        animate();

        const handleScroll = () => {
            const scrollY = window.scrollY;
            const fadeThreshold = 900;
            const fadeOutStart = 100;

            if (cubeRef.current) {
                cubeRef.current.rotation.y = scrollY * 0.003;
                cubeRef.current.rotation.x = scrollY * 0.003;
                const opacity = Math.max(0, 1 - (scrollY - fadeOutStart) / fadeThreshold);
                cubeRef.current.material.opacity = opacity;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            video.pause();
            video.src = "";
            window.removeEventListener('scroll', handleScroll);
            if (mount) {
                mount.removeChild(renderer.domElement);
            }
            renderer.dispose();
        };
    }, [isMobile]);

    if (isMobile) return null;

    return <div ref={mountRef} style={{ width: '100%', height: '100vh', position: 'fixed', top: 0, left: 0, zIndex: -1 }} />;
};

export default CubeBackground3D;
